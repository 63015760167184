import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [HeaderComponent],
    imports: [
        CommonModule,
        NgbModalModule,
        FormsModule
    ],
    exports: [HeaderComponent]
})
export class HeaderModule { }
