import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IAddonCategory } from 'src/app/interfaces/common.interface';

@Injectable({
    providedIn: 'root'
})
export class AddonFilterService {

    public showSearchbar: boolean;
    public searchBarText: string;

    private searchTextSubject = new Subject<string>();
    searchText$ = this.searchTextSubject.asObservable();

    private showFilterSubject = new Subject<Array<IAddonCategory>>();
    showFilter$ = this.showFilterSubject.asObservable();

    setSearchText(text: string) {
        this.searchTextSubject.next(text);
    }

    selectedFilter(value: Array<IAddonCategory>) {
        this.showFilterSubject.next(value);
    }
}
