import { Injectable } from '@angular/core';
import { ILoginData, IWhiteLabelData } from '../interfaces/common.interface';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FuguService {

    private loginData: ILoginData;
    private whiteLabelData: IWhiteLabelData;

    constructor(private commonService: CommonService) {
        const loginData = this.commonService.getCookie('obj');
        this.whiteLabelData = {};
        if (typeof loginData === 'object') {
            if (loginData.accesstoken && !loginData.access_token) {
                loginData.access_token = loginData.accesstoken;
            }
            this.loginData = loginData;
            this.whiteLabelData.is_whitelabel = this.loginData.is_whitelabel || 2;
            this.whiteLabelData.brand_img = this.loginData.logo || '';
        }
    }

    appendFuguScript(): HTMLScriptElement {
        const script = document.createElement('script');
        script.src = 'https://chat.fuguchat.com/js/widget.js';
        script.async = true;
        setTimeout(() => {
            document.body.appendChild(script);
        });
        return script;
    }

    initializeFugu() {
        try {
            (window as any).fuguInit({
                appSecretKey: environment.app_secret_key,
                showData: true,
                color: '#1394ff',
                tags: ['Tookan Dashboard', 'Marketplace'],
                // 'collapseType': 'hide'
            });
        } catch (err) {
            console.warn(err);
        }
    }

    initializeFuguUserData(isCallback: number, tags: string, transactionId: string, message: string) {
        try {
            if (this.loginData && this.loginData.user_id && this.loginData.email && this.whiteLabelData.is_whitelabel !== 1) {
                const emailId = this.loginData.email || '';
                const phoneNumber = this.loginData.phone || '';
                const companyName = this.loginData.company_name || '';
                const userId = this.loginData.user_id || '';
                const name = this.loginData.name || '';
                const createdAt = new Date(this.loginData.creation_datetime).getTime() / 1000;
                const lastLogin = new Date(this.loginData.last_login).getTime();

                const hostname = window.location.host || 'app';
                const hostContent = hostname.split('.') || [];

                const tookanText = ['app.tookanapp.com', 'dev.tookanapp.com', 'dev8.tookanapp.com', 'dev2.tookanapp.com', 'dev4.tookanapp.com', 'dev3.tookanapp.com', 'dev8.tookanapp.com', 'dev7.tookanapp.com', 'dev6.tookanapp.com', 'beta.tookanapp.com', 'extensions-beta.tookanapp.com', 'extensions.tookanapp.com', 'marketplace-beta.tookanapp.com', 'marketplace.tookanapp.com', 'test.tookanapp.com', 'localhost:63342', 'localhost:9191', 'localhost:1111', 'localhost', 'localhost:9090', 'localhost:6060', 'localhost:4200', 'dev9.tookanapp.com','dev17.tookanapp.com','extensions-test.tookanapp.com'];

                if (tookanText.indexOf(hostname) >= 0) {
                    if (!isCallback) {
                        (window as any).fuguInit({
                            appSecretKey: environment.app_secret_key,
                            uniqueId: (userId + '_' + (hostContent[0] || 'app')),
                            name,
                            email: emailId,
                            phone: phoneNumber,
                            showData: true,
                            color: '#1394ff',
                            tags: ['Tookan Dashboard', 'Extensions'],
                            collapseType: 'hide'
                        });
                    } else {
                        (window as any).fuguInit({
                            appSecretKey: environment.app_secret_key,
                            uniqueId: (userId + '_' + (hostContent[0] || 'app')),
                            name,
                            email: emailId,
                            phone: phoneNumber,
                            color: '#1394ff',
                            showData: true,
                            tags: ['Tookan Dashboard'],
                            collapseType: 'hide',
                            callback: () => {
                                (window as any).startConversation({
                                    skipBot: 1,
                                    skipBotReason: 'Tookan Marketplace chat',
                                    tags: [encodeURIComponent(tags), 'Extensions','Tookan-Extension-Force-Assign'],
                                    transaction_id: transactionId + userId,
                                    user_id: userId,
                                    // defaultMessage: message
                                });
                            }
                        });
                    }
                }
            } else if (!isCallback) {
                setTimeout(() => {
                    const win = window.open('https://tookanapp.com/support/', '_blank');
                    win.focus();
                });
            } else if (!this.loginData && this.whiteLabelData.is_whitelabel !== 1 && !isCallback) {
                (window as any).fuguInit({
                    appSecretKey: environment.app_secret_key,
                    showData: true,
                    color: '#1394ff',
                    tags: ['Tookan Dashboard', 'Extensions'],
                    collapseType: 'hide'
                });
            } else if (isCallback) {
                (window as any).fuguInit({
                    appSecretKey: environment.app_secret_key,
                    tags: [encodeURIComponent(tags)],
                    color: '#1394ff',
                    callback: () => {
                        (window as any).startConversation({
                            tags: ['Extensions','Tookan-Extension-Force-Assign'],
                            skipBot: 1,
                            skipBotReason: 'Tookan Marketplace chat',
                            transaction_id: transactionId,
                            // defaultMessage: message
                        });
                    }
                });
            }
        } catch (err) {
            console.warn(err);
        }
    }
}
