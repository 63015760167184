import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { ILoginData } from 'src/app/interfaces/common.interface';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    private loginData: ILoginData;

    constructor(private http: HttpClient,
        private commonService: CommonService) {
        const loginData = this.commonService.getCookie('obj');
        if (typeof loginData === 'object') {
            this.loginData = loginData;
        }
    }

    getUserData(): ILoginData {
        return this.loginData;
    }

    setUserData(loginData: ILoginData) {
        this.commonService.setCookie('obj', loginData);
        this.loginData = loginData;
    }

    loginViaAccessToken(accessToken): Promise<any> {
        const body = {
            access_token: accessToken
        };
        return this.http.post(`${environment.api_url}/user_login_via_access_token`, body).toPromise();
    }

}
