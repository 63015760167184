// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: false,
    api_url: 'https://api.tookanapp.com',
    api_url2: 'https://ip.tookanapp.com:8000',
    integration_url: 'https://api-3112.tookanapp.com',
    gloriafood_url: 'https://api-thor-3011.tookanapp.com',
    api_url_bulbul: 'https://prod-api.bulbul.io',
    fugu_base_url: 'https://api.fuguchat.com',
    dashboard_url: 'https://app.tookanapp.com',
    bulbul_url: 'https://bulbul.io',
    developer_portal_url: 'developers-dev.tookanapp.com',
    stripe_key: 'pk_live_51LrKcVDrlvxIutaZnpVQ9edylDZMTsIZb8su323vfEESuIWnYPpJTavBw2zNV4sIeqV8rZRQN1SPTM21qbsgYD9o00S6CcW7jz',
    maps_api_url: 'https://maps-api.tookanapp.com',
    app_secret_key: '07de4b582efae50d964f4d8e6e548fed',
    google_maps_key: '&client=gme-socomotechnologies&channel=tookan_dashboard'
}

// export const environment = {
//     production: true,


//     api_url: 'https://mohit-api-5500.tookanapp.com:444',   // https://manik-api-3110.tookanapp.com:444
//     api_url2: 'https://ip.tookanapp.com:8000',
//     api_url4: 'https://test-api-2020.tookanapp.com:444',
//     integration_url: 'https://mohit-api-5501.tookanapp.com:444',
//     gloriafood_url: 'https://piyush-api-8032.tookanapp.com:444',
//     deal_pos:'https://test-api-6002.tookanapp.com:444',
//     api_url_bulbul: 'https://prod-api.bulbul.io',
//     fugu_base_url: 'https://api.fuguchat.com',
//     dashboard_url: 'https://app.tookanapp.com',
//     bulbul_url: 'https://bulbul.io',
//     developer_portal_url: 'developers-dev.tookanapp.com',
//     stripe_key: 'pk_live_74J7hPy0ysebzfDtCFkBq3zB',
//     maps_api_url: 'https://maps-api.tookanapp.com',
//     app_secret_key: '07de4b582efae50d964f4d8e6e548fed',
//     google_maps_key: '&client=gme-socomotechnologies&channel=tookan_dashboard'
// };


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
