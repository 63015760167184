import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalytics {

    constructor() { }

    send(category: string, action: string, label: string) {
        try {
            (window as any).ga('send', 'event', {
                eventCategory: category,
                eventAction: action,
                eventLabel: label
            });
        } catch (err) {
            console.warn(err);
        }
    }
}
