import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ILoginData, ISubjectEvent, ICountry, ITollVehicle } from 'src/app/interfaces/common.interface';
import { IWhiteLabelData } from 'src/app/interfaces/common.interface';
import { constants } from 'src/app/constants/common.constants';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    public subject$ :BehaviorSubject<any> = new BehaviorSubject({});
    private loginData: ILoginData;
    private whiteLabelData: IWhiteLabelData;
    private eventSubject: Subject<ISubjectEvent>;
    private editableAddons: Array<number>;
    private intervals: Array<string>;
    private staticApps: any;
    private fuguDashboard: any;
    private tollGuruVehicleTypesList: Array<ITollVehicle>;
    private clearTimeout: any;
    private loaderTimeout: any;
    private resellerToken: string;
    private countries: Array<ICountry>;
    private countriesForPaystack: Array<ICountry>;
    private countriesForRazorpay: Array<ICountry>;
    private mccListForStripe :any;
    public showHeaderLogo:boolean = false;
    public filterHidden:Boolean = false;
    constructor(private title: Title,
        private apiService: ApiService,
        private meta: Meta) {
        const loginData = this.getCookie('obj');
        this.whiteLabelData = {};
        this.eventSubject = new Subject();
        if (typeof loginData === 'object') {
            if (loginData.accesstoken && !loginData.access_token) {
                loginData.access_token = loginData.accesstoken;
            }
            this.loginData = loginData;
            this.whiteLabelData.is_whitelabel = this.loginData.is_whitelabel || 2;
            this.whiteLabelData.brand_img = this.loginData.logo || '';
            this.whiteLabelData.domain = this.loginData.domain || '';
        }
        this.resellerToken = constants.resellerToken;
        this.intervals = constants.intervals;
        this.editableAddons = constants.editableAddons;
        this.staticApps = constants.staticApps;
        this.fuguDashboard = constants.fuguDashboard;
        this.tollGuruVehicleTypesList = constants.tollGuruVehicleTypesList;
        this.countries = constants.countries;
        this.countriesForPaystack = constants.countriesForPaystack;
        this.countriesForRazorpay = constants.countriesForRazorpay;
        
        this.mccListForStripe=constants.mccListForStripe;
    }

    getEditableAddons(): Array<number> {
        return this.editableAddons;
    }

    getmccListForStripeAddons(): Array<any> {
        return this.mccListForStripe;
    }

    getStaticApps(): any {
        return this.staticApps;
    }

    getFuguDashboard(): any {
        return this.fuguDashboard;
    }

    getTollGuruVehicleTypesList(): Array<ITollVehicle> {
        return this.tollGuruVehicleTypesList;
    }

    getIntervals(): Array<string> {
        return this.intervals;
    }

    getCountries(param=1): Array<ICountry> {
        if(param==2) return this.countriesForPaystack;
        if(param==3) return this.countriesForRazorpay;
        return this.countries;
    }

    getEventSubject(): Subject<ISubjectEvent> {
        return this.eventSubject;
    }

    getResellerToken(): string {
        return this.resellerToken;
    }

    setTitle(title?: string) {
        this.title.setTitle(title || 'Tookan Extensions: Explore apps for Tookan products');
    }

    setDescription(description?: string) {
        this.meta.updateTag({
            name: 'description',
            content: description || 'Browse the top apps, add-ons, plugins & integrations for Tookan. Trusted by millions, Tookan is a delivery management software that streamlines operations.'
        });
    }

    getWhiteLabelData(): IWhiteLabelData {
        return this.whiteLabelData;
    }

    setUserData(loginData: ILoginData) {
        this.setCookie('obj', loginData);
        this.loginData = loginData;
    }

    getUserData(): ILoginData {
        return this.loginData;
    }

    async getCacheData(isUserLogin, requestPayload, addOnRequest) {
        let currentDate = new Date();
        let response = isUserLogin ? await this.apiService.getAddons(requestPayload)
            : await this.apiService.getPublicAddons(requestPayload);
        if (response && response.status === 200) {
            if (response.data && Array.isArray(response.data.add_ons)) {
                let addOnResponse = new Response(JSON.stringify(response));
                let a = this;
                caches.open("addonsData").then(function (cache) {
                    cache.keys().then(keys => {
                        keys.forEach(key => {
                            if (key.url.includes('/addOns/addons')) {
                                cache.delete(key);   
                            }
                        })
                    }).then(() => {
                        cache.put(addOnRequest, addOnResponse).then(function (response) {
                            window.localStorage.setItem("lastCachedDate", currentDate.getTime().toString());
                            a.setCookie("readyToCache", true);
                        }).catch(function (error) {
                            console.log("ERROR: " + error);
                        });
                    })
                });
            }
        } else {
            this.setCookie("readyToCache", true);
        }
        return response;
    }

    cacheDataAddOns(requestPayload, doNotReload, callback, errCallback): any {
        let isUserLogin = "access_token" in requestPayload;
        let addOnRequest = isUserLogin ? new Request("/addOns/addons" + JSON.stringify(requestPayload))
            : new Request("/addOns/publicAddons" + JSON.stringify(requestPayload));
        if (addOnRequest) {
            caches.match(addOnRequest, { ignoreSearch: true }).then(async (res) => {
                if (res) {
                    if (!doNotReload) {
                        setTimeout(() => {
                            this.getCacheData(isUserLogin, requestPayload, addOnRequest);
                        }, 0);
                    }
                    return res.json();
                } else {
                    this.showLoader();
                    let response = this.getCacheData(isUserLogin, requestPayload, addOnRequest)
                    this.setCookie("readyToCache", false);
                    return response;
                }
            }).then(callback).catch(errCallback);
        } else {
            return null;
        }
    }

    // getCountryCode() {
    //     let countryCode;
    //     try {
    //         countryCode = localStorage.getItem('country_code');
    //     } catch (e) {
    //         console.log(e); // browser do not support localStorage
    //     }
    //     return countryCode;
    // }

    // setCountryCode(countryCode) {
    //     try {
    //         localStorage.setItem('country_code', countryCode);
    //     } catch (e) {
    //         console.log(e); // browser do not support localStorage
    //     }
    // }

    showLoader() {
        if (this.loaderTimeout) {
            clearTimeout(this.loaderTimeout);
        }
        if (document.getElementById('global_loader')) {
            document.getElementById('global_loader').style.display = 'block';
        }
    }

    hideLoader() {
        if (this.loaderTimeout) {
            clearTimeout(this.loaderTimeout);
        }
        this.loaderTimeout = setTimeout(() => {
            if (document.getElementById('global_loader')) {
                document.getElementById('global_loader').style.display = 'none';
            }
        });
    }

    showSuccessMessage(message: string) {
        if (document.getElementById('success_messsage')) {
            document.getElementById('success_messsage').style.display = 'block';
        }
        if (document.getElementById('error_messsage')) {
            document.getElementById('error_messsage').style.display = 'none';
        }
        if (document.getElementById('msg-ctrl-success')) {
            document.getElementById('msg-ctrl-success').innerHTML = message || '';
        }
        this.clearAllMessages(message);
    }

    showErrorMessage(message: string) {
        if (document.getElementById('success_messsage')) {
            document.getElementById('success_messsage').style.display = 'none';
        }
        if (document.getElementById('error_messsage')) {
            document.getElementById('error_messsage').style.display = 'block';
        }
        if (document.getElementById('msg-ctrl-error')) {
            document.getElementById('msg-ctrl-error').innerHTML = message || '';
        }
        this.clearAllMessages(message);
    }

    clearAllMessages(message: string) {
        const timer = message && message.length > 50 ? 5000 : 3000;
        if (this.clearTimeout) {
            clearTimeout(this.clearTimeout);
        }
        this.clearTimeout = setTimeout(() => {
            if (document.getElementById('msg-ctrl-success')) {
                document.getElementById('msg-ctrl-success').innerHTML = '';
            }
            if (document.getElementById('msg-ctrl-error')) {
                document.getElementById('msg-ctrl-error').innerHTML = '';
            }
            if (document.getElementById('success_messsage')) {
                document.getElementById('success_messsage').style.display = 'none';
            }
            if (document.getElementById('error_messsage')) {
                document.getElementById('error_messsage').style.display = 'none';
            }
        }, timer);
    }

    getCookie(key: string) {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + key + '=');
        if (parts.length === 2) {
            const data = parts.pop().split(';').shift();
            let parsedData;
            try {
                parsedData = JSON.parse(decodeURIComponent(data));
            } catch (e) {
                try {
                    parsedData = decodeURIComponent(data);
                } catch (e) {
                    parsedData = data;
                }
            }
            return parsedData;
        }
    }

    setCookie(key: string, value: any) {
        if (key) {
            try {
                if (typeof value === 'object') {
                    if (window && window.location && window.location.protocol && typeof window.location.protocol === 'string' && window.location.protocol.includes('https:')) {
                        if (key === 'obj') {
                            document.cookie = key + '=' + encodeURIComponent(JSON.stringify(value)) + '; domain=.tookanapp.com; path=/; secure';
                        } else {
                            document.cookie = key + '=' + encodeURIComponent(JSON.stringify(value)) + '; path=/; secure';
                        }
                    } else {
                        if (key === 'obj' && !window.location.hostname.includes('localhost')) {
                            document.cookie = key + '=' + encodeURIComponent(JSON.stringify(value)) + '; domain=.tookanapp.com; path=/';
                        } else {
                            document.cookie = key + '=' + encodeURIComponent(JSON.stringify(value)) + '; path=/';
                        }
                    }
                } else if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                    if (window && window.location && window.location.protocol && typeof window.location.protocol === 'string' && window.location.protocol.includes('https:')) {
                        document.cookie = key + '=' + encodeURIComponent(value) + '; path=/; secure';
                    } else {
                        document.cookie = key + '=' + encodeURIComponent(value) + '; path=/';
                    }
                }
            } catch (e) {
                console.warn(e);
            }
        }
    }

    setDomainCookieProlonged(key: string, value: any) {
        if (key) {
            try {
                if (typeof value === 'object') {
                    document.cookie = key + '=' + encodeURIComponent(JSON.stringify(value)) + ';expires=Thu, 01 Jan 2045 00:00:00 UTC;domain=.tookanapp.com;path=/';
                } else if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                    document.cookie = key + '=' + encodeURIComponent(value) + ';expires=Thu, 01 Jan 2045 00:00:00 UTC;domain=.tookanapp.com;path=/';
                }
            } catch (e) {
                console.warn(e);
            }
        }
    }

    setLocalStorage(key: string, data: any) {
        try {
            localStorage.setItem(key, data);
        } catch (e) {
            console.log(e); // browser do not support localStorage
        }
    }

    snakeCase(data: string): string {
        let parsedData = '';
        if (data) {
            parsedData = (data + '').toLowerCase().replace(/[&]/g, '').replace(/\s+/g, ' ').trim().replace(/\s+/g, '_');
        } else {
            parsedData = data;
        }
        return parsedData;
    }

    copyToClipboard(text: string) {
        var textarea;
        textarea = document.createElement("textarea");
        textarea.style.height = "0px";
        textarea.style.left = "-100px";
        textarea.style.opacity = "0";
        textarea.style.position = "fixed";
        textarea.style.top = "-100px";
        textarea.style.width = "0px";
        document.body.appendChild(textarea);
        textarea.className = "copyTextArea";
        textarea.value = text;
        textarea.select();
        document.execCommand("copy");
      }

}
