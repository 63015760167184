import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AddonsComponent } from 'src/app/components/addons/addons.component';
import { QuicklinkStrategy } from 'ngx-quicklink';

const routes: Routes = [
    {
        path: 'addons',
        component: AddonsComponent,
        data: {
            title: 'Addons'
        }
    }, {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        data: {
            preload: true,
            title: 'Login'
        }
    }, {
        path: 'addon/:name/:id',
        loadChildren: () => import('./modules/addon-overview/addon-overview.module').then(m => m.AddonOverviewModule),
        data: {
            preload: true,
            title: 'Addon'
        }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: '/addons'
    },
    {
        path: '**',
        redirectTo: '/addons'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
