import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormControlName } from '@angular/forms';
import { ILoginData, IAddon, IAddonCategory, IAddonType, IAddonFilter, IWhiteLabelData } from 'src/app/interfaces/common.interface';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AddonsFilterPipe } from './filters/addons-filter.pipe';
import { AddonsService } from './services/addons.service';
import { ApiService } from 'src/app/services/api.service';
import { faSearch, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalytics } from 'src/app/services/googleAnalytics.service';
import { AddonFilterService } from 'src/app/services/addonFilter.service';
import 'lazysizes';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { constants } from 'src/app/constants/common.constants';
@Component({
    selector: 'app-addons',
    templateUrl: './addons.component.html',
    styleUrls: ['./addons.component.scss']
})
export class AddonsComponent implements OnInit {
    @ViewChild('addonContainer', { static: false }) addonContainer!: ElementRef;

    public loginData: ILoginData;
    public whiteLabelData: IWhiteLabelData;
    public countryCode: string = "US";
    public addons: Array<IAddon>;
    public addon: IAddon;
    public addonsUsers: Array<IAddon>;
    public addonsFiltered: Array<IAddon>;
    public popularAddons: Array<number>;
    public addonsUsersFiltered: Array<IAddon>;
    public addonsLoaded: boolean;
    public noPermission: string;
    public isMobile: boolean;
    public eventSubjectSubscription: Subscription;
    public searchAddOnTextSubscription: Subscription;
    public addOnTypeSubscription: Subscription;
    public addOnCategorySubscription: Subscription;
    public addOnCategories: Array<IAddonCategory>;
    public addOnTypes: Array<IAddonType>;
    public addOnFilters: Array<IAddonFilter>;
    public addonFormGroup: FormGroup;
    public addOnType: IAddonType;
    public addOnCategory: FormControl;
    public faSearch: IconDefinition;
    public requestPayload;
    public loginViaAccessToken: boolean;
    public cacheInterval;
    public navTitle = "All Extensions";
    private searchTextSubscription: Subscription;
    private selectedFilterSubscription: Subscription;

    constructor(private sanitizer:DomSanitizer,private commonService: CommonService,
        private apiService: ApiService,
        public addonsService: AddonsService,
        private googleAnalytics: GoogleAnalytics,
        private addonFilter: AddonFilterService,
        private router: Router,
        private modalService: NgbModal,
        private addonsFilterPipe: AddonsFilterPipe) {
        this.loginData = this.commonService.getUserData();
        this.whiteLabelData = this.commonService.getWhiteLabelData();
        this.addons = [];
        this.addonsUsers = [];
        this.addonsFiltered = [];
        this.popularAddons = [];
        this.addonsUsersFiltered = [];
        this.isMobile = /Android.+Mobile|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        this.addonsService.resetAddOnCategories();
        this.addonsService.resetAddOnTypes();
        this.addOnTypes = this.addonsService.getAddOnTypes();
        this.addOnFilters = this.addonsService.getAddOnFilters();
        this.addOnCategory = new FormControl('');
        this.faSearch = faSearch;
        this.searchTextSubscription = this.addonFilter.searchText$.subscribe(text => {
            this.filterAddons();
        });
        this.selectedFilterSubscription = this.addonFilter.showFilter$.subscribe(value => {
            this.addOnCategories = value;
            this.filterAddons();
        });
        this.addOnCategories = this.addonsService.getAddOnCategories();
    }

    ngOnInit() {
        this.loginViaAccessToken = this.commonService.getCookie("loginViaAccessToken");
        this.commonService.setTitle();
        this.commonService.setDescription();
        // const countryCode = this.commonService.getCountryCode();
        this.eventSubjectSubscription = this.commonService.getEventSubject().subscribe((data) => {
            if (data && data.accessTokenLoginData) {
                this.loginData = data.accessTokenLoginData;
            }
            if(data && data.accessTokenLoginData && data.accessTokenLoginData.is_whitelabel){
                this.whiteLabelData.is_whitelabel = data.accessTokenLoginData.is_whitelabel || 2;
                this.whiteLabelData.brand_img = data.accessTokenLoginData.logo || '';
                this.whiteLabelData.domain = data.accessTokenLoginData.domain || '';
            }
        });
        let totalDays = 1;
        let intervalCounter = 0;
        let currentDate = new Date();
        this.addonFilter.showSearchbar=true;
        let lastCachedDate = window.localStorage.getItem("lastCachedDate");
        if (lastCachedDate) {
            let date1 = new Date(Number(lastCachedDate));
            let difference = currentDate.getTime() - date1.getTime();
            totalDays = Math.floor(difference / (1000 * 1800));
        }
        // if (countryCode) {
        // this.countryCode = countryCode;
        if (this.loginViaAccessToken || totalDays > 0) {
            this.getAddons(false);
        } else {
            this.getAddons(true);
        }
        // } else {
        //     this.apiService.fetchCountryCode()
        //         .then((response: any) => {
        //             if (response.data && response.data.country_code) {
        //                 this.countryCode = response.data.country_code;
        //                 this.commonService.setCountryCode(this.countryCode);
        //             }
        //             if (this.loginViaAccessToken || totalDays > 0) {
        //                 this.getAddons(false);
        //             } else {
        //                 this.getAddons(true);
        //             }
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // }
        this.changeAppOwnership();
        if (this.loginViaAccessToken) {
            this.cacheInterval = setInterval(() => {
                intervalCounter++
                if (this.commonService.getCookie('readyToCache')) {
                    this.getAddons(true);
                    this.commonService.setCookie("readyToCache", false)
                    clearInterval(this.cacheInterval);
                }
                if (intervalCounter >= 5) {
                    this.commonService.setCookie("readyToCache", false)
                    clearInterval(this.cacheInterval);
                }
            }, 2000);
        } else {
            this.commonService.setCookie("readyToCache", false)
        }
        this.commonService.setCookie("loginViaAccessToken", false);
    }

    removeUnderscores(label: string): string {
        return label.replace(/_/g, ' ');
      }

    filterAddons() {
        this.addonsFiltered =  this.addonsFilterPipe && this.addonsFilterPipe.transform([...this.addons], this.addonFilter.searchBarText, this.addOnCategories, this.addOnTypes, this.addOnFilters, this.popularAddons);
        this.addonsUsersFiltered = this.addonsFilterPipe && this.addonsFilterPipe.transform([...this.addonsUsers], this.addonFilter.searchBarText, this.addOnCategories, this.addOnTypes, this.addOnFilters, this.popularAddons);
        this.changeNavTitle();
        if (this.addonContainer && this.addonContainer.nativeElement) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth'});
        }
    }

    changeNavTitle(){
        const selectedFilter = this.addOnFilters.find(addOnFilter => addOnFilter.selected);
        const selectedAddOn = this.addOnCategories.find(addOnCategory => addOnCategory.selected);
        if(selectedAddOn){
            this.navTitle = selectedAddOn.label;
        }else if(selectedFilter && 
          (selectedFilter.id == constants.addonFilterMapping.RECOMMENDED || 
           selectedFilter.id == constants.addonFilterMapping.WHATS_NEW ||
           selectedFilter.id == constants.addonFilterMapping.POPULARITY)){
            this.navTitle = selectedFilter.label;
        }else {
            this.navTitle = "All Extensions";
        }
    }

    optionFilter(addOnFilter){
        if (this.addonsService.appOwnership) {
            return addOnFilter.id === 0 || addOnFilter.id === 4 || addOnFilter.id === 5;
        }
        return true;
    }

    changeAppOwnership(){
        this.addonsService.resetAddOnTypes();
        this.addonsService.resetAddOnCategories();
        this.addonsService.resetAddOnFilters();
        this.addonFilter.searchBarText = "";
        this.filterAddons();
    }

    getlink(urlImage):SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(urlImage);
    }


    changeAddOnCategory(selectedCategory: IAddonCategory) {
        if (selectedCategory) {
            selectedCategory.selected = !selectedCategory.selected;
            this.addOnCategories = this.addOnCategories.map(category => {
                if (category !== selectedCategory) {
                    return { ...category, selected: false };
                }
                return category;
            });
            this.filterAddons();
            this.googleAnalytics.send('marketplace_v2', 'category_filter', 'category_filter');
        }
    }

    changeAddOnType(selectedAddonType) {
        this.addOnTypes.map((addOnTypeV2: IAddonType) => {
            if (selectedAddonType === addOnTypeV2.label) {
                addOnTypeV2.selected = true;
            } else {
                addOnTypeV2.selected = false;
            }
        });
        this.filterAddons();
        if (this.addonsService.getAddOnTypes() && this.addonsService.getAddOnTypes()[0] && selectedAddonType === this.addonsService.getAddOnTypes()[0].label) {
            this.googleAnalytics.send('marketplace_v2', 'category_filter', 'select_all');
        } else {
            this.googleAnalytics.send('marketplace_v2', 'category_filter', 'category_filter');
        }
    }

    resetAllFields(){
        this.addonsService.resetAddOnTypes();
        this.addonsService.resetAddOnCategories();
        this.addonFilter.searchBarText = "";
        this.addonsService.appOwnership = false;
    }

    changeAddOnFilter(selectedAddonFilter) {
        this.addOnFilters.map((addOnFilterV2: IAddonType) => {
            if (selectedAddonFilter === addOnFilterV2.label) {
                addOnFilterV2.selected = true;
            } else {
                addOnFilterV2.selected = false;
            }
        });
        if(this.addOnFilters[3].selected){
            this.resetAllFields();
        }
        this.filterAddons();
        if (this.addonsService.getAddOnFilters() && this.addonsService.getAddOnFilters()[0] && selectedAddonFilter === this.addonsService.getAddOnFilters()[0].label) {
            this.googleAnalytics.send('marketplace_v2', 'category_filter', 'select_all');
        } else {
            this.googleAnalytics.send('marketplace_v2', 'category_filter', 'category_filter');
        }
    }
    
    // getCountryCode(countryCode, loginData){
    //     this.commonService.setCookie('billing_plan', loginData.billing_plan);
    //     if(loginData && loginData.billing_plan==0){
    //             return "US";
    //         }
    //         return countryCode;
    // };
 
    trackById(index, contact) {
        return contact.add_on_type;
    }

    getAddons(doNotReload) {
        this.addonsLoaded = false;
        this.noPermission = '';
        if (this.loginData && this.loginData.access_token) {
            this.requestPayload = {
                country_code: this.countryCode,
                access_token: this.loginData.access_token
            };
            this.commonService.cacheDataAddOns(this.requestPayload, doNotReload, (data) => {
                this.addons = data.data.add_ons;
                this.addonsFiltered = data.data.add_ons;
                if (data.data && data.data.user_add_ons && Array.isArray(data.data.user_add_ons)) {
                    this.addonsUsers = data.data.user_add_ons;
                    this.addonsUsersFiltered = data.data.user_add_ons;
                    this.popularAddons = data.data.popular_add_ons || [];
                }
                this.commonService.hideLoader();
                this.addonsLoaded = true;
                this.googleAnalytics.send('marketplace_v2_addon_detail_page_opened', this.addon && this.addon.add_on_name, this.loginData && this.loginData.user_id && this.loginData.user_id.toString() || 'pre_login');
            }, (error) => {
                this.addonsLoaded = true;
                this.commonService.hideLoader();
            });
        } else {
            this.requestPayload = {
                country_code: this.countryCode
            };
            this.commonService.cacheDataAddOns(this.requestPayload, doNotReload, (response) => {
                this.noPermission = response.message;
                this.addons = response.data.add_ons;
                this.addonsFiltered = response.data.add_ons;
                this.popularAddons = response.data.popular_add_ons || [];
                this.commonService.hideLoader();
                this.addonsLoaded = true;
                this.googleAnalytics.send('marketplace_v2_addon_detail_page_opened', this.addon && this.addon.add_on_name, this.loginData && this.loginData.user_id && this.loginData.user_id.toString() || 'pre_login');
            }, (error) => {
                this.addonsLoaded = true;
                this.commonService.hideLoader();
            });
        }
        this.commonService.showHeaderLogo=true;
    }

    showDetailPage(addon: IAddon) {
        if (addon) {
            this.router.navigate(['addon/' + (addon.add_on_name + '').replace(/\s|\t|&|:/g, '-').replace(/\(|\)/g, '') + '/' + addon.add_on_type]);
            this.googleAnalytics.send('marketplace_v2', 'addon_details', addon.add_on_name);
        }
    }

    openModal(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-read-more' }).result.then((result) => {
            // console.log(result);
        }, (reason) => {
            // console.log(reason);
        });
    }

    openReadMore(addon: IAddon, event: Event, content) {
        this.addon = addon;
        this.openModal(content);
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    getSubstring(description: string, limit: number) {
        if (description.length > limit) {
            if (!(description.length !== limit + 1 && description[limit] === '.')) {
                description = description.substring(0, limit) + '...';
            }
        }
        return (description);
    }

    ngOnDestroy() {
        if (this.eventSubjectSubscription) {
            this.eventSubjectSubscription.unsubscribe();
        }
        if (this.searchAddOnTextSubscription) {
            this.searchAddOnTextSubscription.unsubscribe();
        }
        if (this.addOnTypeSubscription) {
            this.addOnTypeSubscription.unsubscribe();
        }
        if (this.addOnCategorySubscription) {
            this.addOnCategorySubscription.unsubscribe();
        }
        if(this.searchTextSubscription) {
            this.searchTextSubscription.unsubscribe();
        }
        if(this.selectedFilterSubscription){
            this.selectedFilterSubscription.unsubscribe();
        }
    }

}


