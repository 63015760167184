import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ILoginData, IWhiteLabelData, IAddonCategory } from 'src/app/interfaces/common.interface';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { HeaderService } from '../services/header.service';
import 'lazysizes';
import { GoogleAnalytics } from 'src/app/services/googleAnalytics.service';
import { AddonFilterService } from 'src/app/services/addonFilter.service';
import { AddonsService } from 'src/app/components/addons/services/addons.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @ViewChild('topNav', { static: false }) public topNav: ElementRef;

    public loginData: ILoginData;
    public eventSubjectSubscription: Subscription;
    public accessToken: string;
    public dashboardUrl: string;
    public showGoToDashboard: boolean;
    public whiteLabelData: IWhiteLabelData;
    public routeSubscription: Subscription;
    public routerSubscription: Subscription;
    public queryParams: string;
    public dashboardWindow: Window;
    public isAddonRoute: boolean;
    public showLogo: boolean=false;
    public addOnCategories: Array<IAddonCategory>;

    constructor(private router: Router,
        private renderer: Renderer2,
        public commonService: CommonService,
        private headerService: HeaderService,
        private modalService: NgbModal,
        private googleAnalytics: GoogleAnalytics,
        public addonFilter: AddonFilterService,
        private addonsService: AddonsService,
        private route: ActivatedRoute) {
        this.loginData = this.commonService.getUserData();
        this.dashboardUrl = environment.dashboard_url;
        this.whiteLabelData = this.commonService.getWhiteLabelData();
        this.addOnCategories = this.addonsService.getAddOnCategories();
        this.queryParams = '';
        if (this.loginData && this.loginData.access_token) {
            if (this.loginData.is_dispatcher) {
                window.location.href = this.dashboardUrl + '/#/app/dashboard';
            } else {
                this.showGoToDashboard = false;
                this.loginViaAccessToken(this.loginData.access_token);
            }
        }
        this.routeSubscription = this.route.queryParams.subscribe(params => {
            this.accessToken = params['access_token'];
            // if (this.accessToken) {
            //     this.loginViaAccessToken(this.accessToken);
            // }
            const parameters = [];
            Object.keys(params).map((key) => {
                parameters.push(key + '=' + params[key]);
            });
            this.queryParams = parameters.join('&');
        });
        this.routerSubscription = this.router.events.subscribe((url) => {
            if (url instanceof NavigationEnd) {
                if (url.urlAfterRedirects === '/addons') {
                    this.isAddonRoute = true;
                    this.getNavbarStyle();
                } else {
                    this.isAddonRoute = false;
                    this.getNavbarStyle();
                }
            }
        });
        this.eventSubjectSubscription = this.commonService.getEventSubject().subscribe((data) => {
            if (data && data.accessTokenLoginData) {
                this.loginData = data.accessTokenLoginData;
            }
            if(data && data.accessTokenLoginData && data.accessTokenLoginData.is_whitelabel){
                this.whiteLabelData.is_whitelabel = data.accessTokenLoginData.is_whitelabel || 2;
                this.whiteLabelData.brand_img = data.accessTokenLoginData.logo || '';
                this.whiteLabelData.domain = data.accessTokenLoginData.domain || '';
            }
            this.commonService.showHeaderLogo = true;
            this.showGoToDashboard = true;
        });
    }

    @HostListener('window:scroll', ['$event'])
    getScrollEvent(event) {
        this.getNavbarStyle();
    }

    ngOnInit() {
        this.filterHidden()
    }

    filterHidden(){
        if(sessionStorage.getItem('manager_app') && sessionStorage.getItem('manager_app') == '1'){
            this.commonService.filterHidden = true
        }else{
            this.commonService.filterHidden = false 
        }
    }

    ngAfterViewInit() {

    }

    getNavbarStyle() {
        if (this.topNav && this.topNav.nativeElement) {
            if (this.isAddonRoute) {
                if (window.pageYOffset > 60) {
                    this.renderer.setStyle(this.topNav.nativeElement, 'background-color', '#fff');
                } else {
                    this.renderer.setStyle(this.topNav.nativeElement, 'background-color', '#fff');
                }
            } else {
                this.renderer.setStyle(this.topNav.nativeElement, 'background-color', '#fff');
            }
        }
    }

    loginViaAccessToken(accessToken) {
        this.headerService.loginViaAccessToken(accessToken)
            .then(response => {
                if (response && response.status === 200) {
                    if (response.data && typeof response.data === 'object') {
                        let userData = {
                            access_token: response.data.access_token,
                            is_dispatcher: response.data.is_dispatcher,
                            user_id: response.data.user_id,
                            email: response.data.email,
                            phone: response.data.phone,
                            name: response.data.username,
                            company_name: response.data.company_name,
                            company_email: response.data.company_email,
                            creation_datetime: response.data.creation_datetime,
                            last_login: response.data.last_login_datetime,
                            company_address: response.data.company_address,
                            expiry_datetime: response.data.expiry_datetime,
                            billing_expiry: response.data.billing_expiry,
                            billing_plan: response.data.billing_plan,
                            layout_type: response.data.layout_type,
                            call_fleet_as: response.data.call_fleet_as,
                            call_dispatcher_as: response.call_dispatcher_as
                        };
                        const loginData = this.commonService.getCookie('obj');
                        if (typeof loginData === 'object') {
                            userData = Object.assign(loginData, userData);
                        }
                        this.headerService.setUserData(userData as any);
                        this.commonService.setUserData(userData as any);
                    }
                    this.showGoToDashboard = true;
                    this.commonService.getEventSubject().next({
                        accessTokenLoginData: response.data
                    });
                } else {
                    this.commonService.showErrorMessage(response && response.message);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    goToHome() {
        this.router.navigate(['addons']);
    }

    goToTookanDashboard() {
        setTimeout(() => {
            if(this.whiteLabelData.is_whitelabel==1 && this.whiteLabelData.domain){
                this.dashboardWindow = window.open(this.whiteLabelData.domain, '_blank');
            }else{
                if (this.dashboardWindow) {
                    this.dashboardWindow.close();
                    this.dashboardWindow = window.open(environment.dashboard_url, '_blank');
                } else {
                    this.dashboardWindow = window.open(environment.dashboard_url, '_blank');
                }
            }
        });
        this.googleAnalytics.send('marketplace_v2', 'goto_Tookan_click', 'goto_Tookan_click');
    }

    loginTookan() {
        const queryParams = this.queryParams ? '&' + this.queryParams : this.queryParams;
        window.location.href = this.dashboardUrl ? this.dashboardUrl +
            '/#/page/login' : 'https://app.tookanapp.com';
    }

    searchFilter() {
        this.addonFilter.setSearchText(this.addonFilter.searchBarText);
    }

    openFilter(content) {
        this.modalService.open(content, { centered: false, windowClass: 'modal-top-right header-filter' });
    }

    changeAddOnCategory(selectedCategory: IAddonCategory){
        if (selectedCategory) {
            selectedCategory.selected = !selectedCategory.selected;
            this.addOnCategories = this.addOnCategories.map(category => {
                if (category !== selectedCategory) {
                    return { ...category, selected: false };
                }
                return category;
            });
        }
        this.addonFilter.selectedFilter(this.addOnCategories);
    }

    ngOnDestroy() {
        if (this.eventSubjectSubscription) {
            this.eventSubjectSubscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

}
