import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { HeaderModule } from 'src/app/modules/header/header.module';
import { AppComponent } from './app.component';
import { AddonsComponent } from './components/addons/addons.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddonsFilterPipe } from './components/addons/filters/addons-filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuicklinkModule } from 'ngx-quicklink';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SafePipe } from './components/addons/filters/safe.pipe';


@NgModule({
    declarations: [
        AppComponent,
        AddonsComponent,
        AddonsFilterPipe,
        SafePipe
    ],
    imports: [
        BrowserModule,
        NgSelectModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        HeaderModule,
        FontAwesomeModule,
        QuicklinkModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [AddonsFilterPipe],
    bootstrap: [AppComponent]
})
export class AppModule { }
