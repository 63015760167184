import { Pipe, PipeTransform } from '@angular/core';
import { IAddon, IAddonCategory, IAddonType, IAddonFilter } from '../../../interfaces/common.interface';
import { AddonsService } from '../services/addons.service';
import { constants } from 'src/app/constants/common.constants';

@Pipe({
    name: 'addonsFilter'
})
export class AddonsFilterPipe implements PipeTransform {

    constructor(private addonsService: AddonsService) { }

    transform(addons: Array<IAddon>,
        searchText: string,
        addOnCategories: Array<IAddonCategory>,
        addOnTypes: Array<IAddonType>,
        addOnFilters: Array<IAddonFilter>,
        popularAddons: Array<number>
        ): Array<IAddon> {
        const selectedAddOnCategories = addOnCategories.filter((category: IAddonCategory) => {
            if (category.selected) {
                return category;
            }
        });
        const selectedAddOnTypes = addOnTypes.filter((type: IAddonCategory) => {
            if (type.selected) {
                return type;
            }
        });
        const selectedAddonFilter = addOnFilters.filter((type: IAddonCategory) => {
            if (type.selected) {
                return type;
            }
        });

        let finalAddonsArray = addons;
        if(selectedAddonFilter.length > 0){
            finalAddonsArray = this.addonFilter(finalAddonsArray, selectedAddonFilter, popularAddons)
        }
        if (searchText) {
            finalAddonsArray = finalAddonsArray.filter((addon: IAddon) => {
                if ((addon.add_on_name + '').toLowerCase().includes((searchText + '').toLowerCase()) ||
                    (addon.description + '').toLowerCase().includes((searchText + '').toLowerCase()) || 
                    (addon.categories + '').toLowerCase().includes((searchText + '').toLowerCase()) || (addon.tags + '').toLowerCase().includes((searchText + '').toLowerCase())) {
                    if (selectedAddOnCategories.length > 0) {
                        if (this.filterCategories(addon, selectedAddOnCategories)) {
                            if (this.filterPrice(addon, selectedAddOnTypes)) {
                                return addon;
                            }
                        }
                    } else {
                        if (this.filterPrice(addon, selectedAddOnTypes)) {
                            return addon;
                        }
                    }
                }
            });
        } else {
            finalAddonsArray = finalAddonsArray.filter((addon: IAddon) => {
                if (selectedAddOnCategories.length > 0) {
                    if (this.filterCategories(addon, selectedAddOnCategories)) {
                        if (this.filterPrice(addon, selectedAddOnTypes)) {
                            return addon;
                        }
                    }
                } else {
                    if (this.filterPrice(addon, selectedAddOnTypes)) {
                        return addon;
                    }
                }
            });
        }

        return finalAddonsArray;
    }

    addonFilter(addons: Array<IAddon>, selectedAddonFilter: Array<IAddonFilter>, popularAddons: Array<number>){
        if(selectedAddonFilter[0].id == constants.addonFilterMapping.RECOMMENDED){
            addons = addons.filter((addon: IAddon) => {
                if (selectedAddonFilter.length > 0) {
                    if (this.filterCategories(addon, selectedAddonFilter)) {
                        return addon;
                    }
                }
            });
        }
        if(selectedAddonFilter[0].id == constants.addonFilterMapping.WHATS_NEW){
            if(addons.length > 10){
                addons = addons.slice(-10);
            }
            addons = addons.reverse();
        }
        if(selectedAddonFilter[0].id == constants.addonFilterMapping.POPULARITY){
            addons = addons.filter(addon => popularAddons.includes(addon.add_on_type));
        }
        if(selectedAddonFilter[0].id == constants.addonFilterMapping.SORT_A_TO_Z){
            addons.sort((a, b) => a.add_on_name.localeCompare(b.add_on_name));
        }
        if(selectedAddonFilter[0].id == constants.addonFilterMapping.SORT_Z_TO_A){
            addons.sort((a, b) => b.add_on_name.localeCompare(a.add_on_name));
        }
        return addons;
    }

    filterCategories(addon: IAddon, selectedAddOnCategories: Array<IAddonCategory>): boolean {
        const categories = (addon.categories + '').trim().replace(/[,]\s+/g, ',').toLowerCase().split(',');
        const categoriesLength = selectedAddOnCategories.filter((category: IAddonCategory) => {
            if (categories.indexOf(category.label) !== -1) {
                return category;
            }
        }).length;
        if (categoriesLength > 0) {
            return true;
        } else {
            return false;
        }
    }

    filterPrice(addon: IAddon, addOnTypes: Array<IAddonType>): boolean {
        const typesLength = addOnTypes.filter((type) => {
            if (this.addonsService.getAddOnTypes()[1] && (type.label + '').toLowerCase() === this.addonsService.getAddOnTypes()[1].label) {
                if ((addon.per_fleet_cost + addon.per_task_cost + addon.per_unit_cost) > 0) {
                    return type;
                } else if (this.addonsService.getAddOnTypes()[2] && (addon.pricing_card + '').toLowerCase() !== this.addonsService.getAddOnTypes()[2].label) {
                    return type;
                }
            } else if (this.addonsService.getAddOnTypes()[0] && (type.label + '').toLowerCase() === this.addonsService.getAddOnTypes()[0].label) {
                return type;
            } else if ((type.label + '').toLowerCase() === (addon.pricing_card + '').toLowerCase()) {
                return type;
            }
        }).length;
        if (typesLength > 0) {
            return true;
        } else {
            return false;
        }
    }

}
