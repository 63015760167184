import { Component } from '@angular/core';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FuguService } from './services/fugu.service';
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs/internal/Subscription';
import { CommonService } from './services/common.service';
import { RouterEvent, Router, NavigationStart } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    public title = 'Marketplace';
    public faTimes: IconDefinition;
    public online: boolean;
    public eventOnlineSubscription: Subscription;
    public eventOfflineSubscription: Subscription;
    public routerSubscription: Subscription;

    constructor(private config: NgbModalConfig,
        private commonService: CommonService,
        private fuguService: FuguService,
        private router: Router) {
        if (window && window.navigator) {
            this.online = window.navigator.onLine;
        } else {
            this.online = true;
        }
        this.faTimes = faTimes;
        this.config.centered = true;
        this.fuguService.appendFuguScript().onload = () => {
            this.fuguService.initializeFugu();
        };

        this.eventOnlineSubscription = fromEvent(window, 'online').pipe(debounceTime(1000)).subscribe(e => {
            this.online = true;
            this.commonService.showSuccessMessage('You are back online.');
            // setTimeout(() => {
            //     this.refesh();
            // }, 1000);
        });

        this.eventOfflineSubscription = fromEvent(window, 'offline').pipe(debounceTime(1000)).subscribe(e => {
            this.online = false;
            this.commonService.showErrorMessage('You are offline. Please check your internet connection.');
        });

        this.routerSubscription = this.router.events.subscribe((routerEvent: RouterEvent) => {
            if (routerEvent instanceof NavigationStart) {
                if (!this.online) {
                    this.commonService.showErrorMessage('You are offline. Please check your internet connection.');
                }
            }
        });

        // try {
        //     this.preloadFonts();
        // } catch (e) {
        //     console.warn(e);
        // }
    }

    refesh() {
        try {
            window.location.reload();
        } catch (e) {
            console.warn(e);
        }
    }

    preloadFonts() {
        // const link = document.createElement('link');
        // link.rel = 'preload';
        // link.as = 'font';
    }

    preloadAsset(link: HTMLLinkElement, href: string, type: string, crossOrigin?: string) {
        // if (crossOrigin) {
        //     link.crossOrigin = crossOrigin;
        // }
        // link.href = href;
        // link.type = type;
        // document.head.appendChild(link);
    }

    removeMessage() {
        if (document.getElementById('msg-ctrl-success')) {
            document.getElementById('msg-ctrl-success').innerHTML = '';
        }
        if (document.getElementById('msg-ctrl-error')) {
            document.getElementById('msg-ctrl-error').innerHTML = '';
        }
        if (document.getElementById('success_messsage')) {
            document.getElementById('success_messsage').style.display = 'none';
        }
        if (document.getElementById('error_messsage')) {
            document.getElementById('error_messsage').style.display = 'none';
        }
    }

    ngOnDestroy() {
        if (this.eventOnlineSubscription) {
            this.eventOnlineSubscription.unsubscribe();
        }
        if (this.eventOfflineSubscription) {
            this.eventOfflineSubscription.unsubscribe();
        }
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

}
