export const constants = {
    startScheduleTime: '09:00',
    endScheduleTime: '17:00',
    breakStartTime: '00:00',
    breakEndTime: '00:00',
    resellerToken: '63484e31310fd2f66c7a9093c45ddc2b',
    fuguAuthTokenAgent: '63484e31310fd2f66c7a9093c45ddc2b',
    fuguAuthTokenBusiness: '63484e31310fd2f66c7a9093c45ddc2b',
    intervals: ['Daily', 'Weekly', 'Monthly'],
    editableAddons: [1, 4, 8, 10, 11, 13, 32, 49, 107, 108, 109, 112,126, 168, 178, 179, 180, 187, 201, 204, 208, 276, 232, 261, 269, 147, 320, 319, 312, 321, 323, 325, 326, 327, 332, 339, 340, 343, 344, 347, 348, 370, 1000],
    staticApps: {
        redirectTrackingLink: {
            id: 201,
            name: 'Redirect URL for Tracking Link'
        },
        fuguChatIntegration: {
            id: 206,
            name: 'Fugu Integration'
        },
        fleetWallet: {
            id: 112,
            name: 'Fleet Wallet'
        },
        tollguru: {
            id: 204,
            name: 'TollGuru'
        },
        customerSchedule: {
            id: 224,
            name: 'Customer schedule'
        }
    },
    fuguDashboard: {
        login_url: 'https://hippochat.io/#/dashboard/my/chats/'
    },
    tollGuruVehicleTypesList: [{
        name: '',
        description: 'Please select a default vehicle type'
    }, {
        name: '2AxlesAuto',
        description: '2AxlesAuto'
    }, {
        name: '3AxlesAuto',
        description: '3AxlesAuto (towing 1-axle trailer)'
    }, {
        name: '4AxlesAuto',
        description: '4AxlesAuto (towing 2-axle trailer)'
    }, {
        name: '2AxlesDualTire',
        description: '2AxlesDualTire'
    }, {
        name: '3AxlesDualTire',
        description: '3AxlesDualTire'
    }, {
        name: '4AxlesDualTire',
        description: '4AxlesDualTire'
    }, {
        name: '2AxlesTruck',
        description: '2AxlesTruck'
    }, {
        name: '3AxlesTruck',
        description: '3AxlesTruck'
    }, {
        name: '4AxlesTruck',
        description: '4AxlesTruck'
    }, {
        name: '5AxlesTruck',
        description: '5AxlesTruck'
    }, {
        name: '6AxlesTruck',
        description: '6AxlesTruck'
    }, {
        name: '7AxlesTruck',
        description: '7AxlesTruck'
    }, {
        name: '8AxlesTruck',
        description: '8AxlesTruck'
    }, {
        name: '9AxlesTruck',
        description: '9AxlesTruck'
    }, {
        name: '2AxlesBus',
        description: '2AxlesBus'
    }, {
        name: '3AxlesBus',
        description: '3AxlesBus'
    }, {
        name: '2AxlesMotorcycle',
        description: '2AxlesMotorcycle'
    }, {
        name: '3AxlesMotorcycle',
        description: '3AxlesMotorcycle (towing a trailer)'
    }, {
        name: '2AxlesRv',
        description: '2AxlesRv'
    }, {
        name: '3AxlesRv',
        description: '3AxlesRv'
    }, {
        name: '4AxlesRv',
        description: '4AxlesRv'
    }],
    countries: [{
        name: 'Australia',
        code: 'AU',
        currencies: 'AUD'
    }, {
        name: 'Austria',
        code: 'AT',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Belgium',
        code: 'BE',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Brazil',
        code: 'BR',
        currencies: 'BRL'
    }, {
        name: 'Canada',
        code: 'CA',
        currencies: 'CAD,USD'
    }, {
        name: 'Denmark',
        code: 'DK',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Finland',
        code: 'FI',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'France',
        code: 'FR',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Germany',
        code: 'DE',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Hong Kong',
        code: 'HK',
        currencies: 'HKD'
    }, {
        name: 'Ireland',
        code: 'IE',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Italy',
        code: 'IT',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Japan',
        code: 'JP',
        currencies: 'JPY'
    }, {
        name: 'Luxembourg',
        code: 'LU',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Mexico',
        code: 'MX',
        currencies: 'MXN'
    }, {
        name: 'Netherlands',
        code: 'NL',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'New Zealand',
        code: 'NZ',
        currencies: 'NZD'
    }, {
        name: 'Norway',
        code: 'NO',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Portugal',
        code: 'PT',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Singapore',
        code: 'SG',
        currencies: 'SGD'
    }, {
        name: 'Spain',
        code: 'ES',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Sweden',
        code: 'SE',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'Switzerland',
        code: 'CH',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'United Kingdom',
        code: 'GB',
        currencies: 'EUR,DKK,GBP,NOK,SEK,USD,CHF'
    }, {
        name: 'United States',
        code: 'US',
        currencies: 'USD'
    }],
    countriesForPaystack: [{
        name: 'Nigeria',
        code: 'NGA',
        currencies: 'NGN'
    }],
    countriesForRazorpay: [{
        name: 'India',
        code: 'IN',
        currencies: 'INR'
    }],
    addOnFilters: [{
        label: 'all',
        selected: true,
        id: 0
    }, {
        label: 'recommended',
        selected: false,
        id: 1
    }, {
        label: "What's New",
        selected: false,
        id: 2
    }, {
        label: 'Top Extensions',
        selected: false,
        id: 3
    }, {
        label: 'Sort: A-Z',
        selected: false,
        id: 4
    }, {
        label: 'Sort: Z-A',
        selected: false,
        id: 5
    }],
    addonFilterMapping : {
        ALL : 0,
        RECOMMENDED : 1,
        WHATS_NEW : 2,
        POPULARITY : 3,
        SORT_A_TO_Z : 4,
        SORT_Z_TO_A : 5
    },
    addOnTypes: [{
        label: 'all',
        selected: true
    }, {
        label: 'paid',
        selected: false
    }, {
        label: 'free',
        selected: false
    }],
    addOnCategories: [{
        label: 'agent',
        selected: false
    }, {
        label: 'customer',
        selected: false
    }, {
        label: 'utility',
        selected: false
    }, {
        label: 'payment',
        selected: false
    }, {
        label: 'pos',
        selected: false
    }, {
        label: 'sms',
        selected: false
    },{
        label: 'delivery_orchestration',
        selected: false
    }],
    mccListForStripe: {
        7623: {
            id: "7623",
            name: "A/C, Refrigeration Repair"
        },
        8931: {
            id: "8931",
            name: "Accounting/Bookkeeping Services",
        },
        7311: {
            id: "7311",
            name: "Advertising Services"
        },
        //"0763":{
        //    id:"0763",
        //    name:"Agricultural Cooperative"
        //}
        "4511": {
            id: "4511",
            name: "Airlines, Air Carriers"
        },
        "4582": {
            id: "4582",
            name: "Airports, Flying Fields"
        },
        "4119": {
            id: "4119",
            name: "Ambulance Services"
        },
        "7996": {
            id: "7996",
            name: "Amusement Parks/Carnivals"
        },
        "5937": {
            id: "5937",
            name: "Antique Reproductions"
        },
        "5932": {
            id: "5932",
            name: "Antique Shops"
        },
        "7998": {
            id: "7998",
            name: "Aquariums"
        },
        "8911": {
            id: "8911",
            name: "Architectural/Surveying Services"
        },
        "5971": {
            id: "5971",
            name: "Art Dealers and Galleries"
        },
        "5970": {
            id: "5970",
            name: "Artists Supply and Craft Shops"
        },
        "7531": {
            id: "7531",
            name: "Auto Body Repair Shops"
        },
        "7535": {
            id: "7535",
            name: "Auto Paint Shops"
        },
        "7538": {
            id: "7538",
            name: "Auto Service Shops"
        },
        "5531": {
            id: "5531",
            name: "Auto and Home Supply Stores"
        },
        "6011": {
            id: "6011",
            name: "Automated Cash Disburse"
        },
        "5542": {
            id: "5542",
            name: "Automated Fuel Dispensers"
        },
        "8675": {
            id: "8675",
            name: "Automobile Associations"
        },
        "5533": {
            id: "5533",
            name: "Automotive Parts and Accessories Stores"
        },
        "5532": {
            id: "5532",
            name: "Automotive Tire Stores"
        },
        "9223": {
            id: "9223",
            name: "Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency)"
        },
        "5462": {
            id: "5462",
            name: "Bakeries"
        },
        "7929": {
            id: "7929",
            name: "Bands, Orchestras"
        },
        "7230": {
            id: "7230",
            name: "Barber and Beauty Shops"
        },
        "7995": {
            id: "7995",
            name: "Betting/Casino Gambling"
        },
        "5940": {
            id: "5940",
            name: "Bicycle Shops"
        },
        "7932": {
            id: "7932",
            name: "Billiard/Pool Establishment"
        },
        "5551": {
            id: "5551",
            name: "Boat Dealers"
        },
        "4457": {
            id: "4457",
            name: "Boat Rentals and Leases"
        },
        "5942": {
            id: "5942",
            name: "Book Stores"
        },
        "5192": {
            id: "5192",
            name: "Books, Periodicals, and Newspapers"
        },
        "7933": {
            id: "7933",
            name: "Bowling Alleys"
        },
        "4131": {
            id: "4131",
            name: "Bus Lines"
        },
        "8244": {
            id: "8244",
            name: "Business/Secretarial Schools"
        },
        "7278": {
            id: "7278",
            name: "Buying/Shopping Services"
        },
        "4899": {
            id: "4899",
            name: "Cable, Satellite, and Other Pay Television and Radio"
        },
        "5946": {
            id: "5946",
            name: "Camera and Photographic Supply Stores"
        },
        "5441": {
            id: "5441",
            name: "Candy, Nut, and Confectionery Stores"
        },
        "7512": {
            id: "7512",
            name: "Car Rental Agencies"
        },
        "7542": {
            id: "7542",
            name: "Car Washes"
        },
        "5511": {
            id: "5511",
            name: "Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing"
        },
        "5521": {
            id: "5521",
            name: "Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing"
        },
        "1750": {
            id: "1750",
            name: "Carpentry Services"
        },
        "7217": {
            id: "7217",
            name: "Carpet/Upholstery Cleaning"
        },
        "5811": {
            id: "5811",
            name: "Caterers"
        },
        "8398": {
            id: "8398",
            name: "Charitable and Social Service Organizations - Fundraising"
        },
        "5169": {
            id: "5169",
            name: "Chemicals and Allied Products (Not Elsewhere Classified)"
        },
        "8351": {
            id: "8351",
            name: "Child Care Services"
        },
        "5641": {
            id: "5641",
            name: "Childrens and Infants Wear Stores"
        },
        "8049": {
            id: "8049",
            name: "Chiropodists, Podiatrists"
        },
        "8041": {
            id: "8041",
            name: "Chiropractors"
        },
        "5993": {
            id: "5993",
            name: "Cigar Stores and Stands"
        },
        "8641": {
            id: "8641",
            name: "Civic, Social, Fraternal Associations"
        },
        "7349": {
            id: "7349",
            name: "Cleaning and Maintenance"
        },
        "7296": {
            id: "7296",
            name: "Clothing Rental"
        },
        "8220": {
            id: "8220",
            name: "Colleges, Universities"
        },
        "5046": {
            id: "5046",
            name: "Commercial Equipment (Not Elsewhere Classified)"
        },
        "5139": {
            id: "5139",
            name: "Commercial Footwear"
        },
        "7333": {
            id: "7333",
            name: "Commercial Photography, Art and Graphics"
        },
        "4111": {
            id: "4111",
            name: "Commuter Transport, Ferries"
        },
        "4816": {
            id: "4816",
            name: "Computer Network Services"
        },
        "7372": {
            id: "7372",
            name: "Computer Programming"
        },
        "7379": {
            id: "7379",
            name: "Computer Repair"
        },
        "5734": {
            id: "5734",
            name: "Computer Software Stores"
        },
        "5045": {
            id: "5045",
            name: "Computers, Peripherals, and Software"
        },
        "1771": {
            id: "1771",
            name: "Concrete Work Services"
        },
        "5039": {
            id: "5039",
            name: "Construction Materials (Not Elsewhere Classified)"
        },
        "7392": {
            id: "7392",
            name: "Consulting, Public Relations"
        },
        "8241": {
            id: "8241",
            name: "Correspondence Schools"
        },
        "5977": {
            id: "5977",
            name: "Cosmetic Stores"
        },
        "7277": {
            id: "7277",
            name: "Counseling Services"
        },
        "7997": {
            id: "7997",
            name: "Country Clubs"
        },
        "4215": {
            id: "4215",
            name: "Courier Services"
        },
        "9211": {
            id: "9211",
            name: "Court Costs, Including Alimony and Child Support - Courts of Law"
        },
        "7321": {
            id: "7321",
            name: "Credit Reporting Agencies"
        },
        "4411": {
            id: "4411",
            name: "Cruise Lines"
        },
        "5451": {
            id: "5451",
            name: "Dairy Products Stores"
        },//Dance Hall, Studios, Schools	7911
        "7273": {
            id: "7273",
            name: "Dating/Escort Services"
        }, //Dentists, Orthodontists	8021
        "5311": {
            id: "5311",
            name: "Department Stores"
        },//Detective Agencies	7393
        // Digital Goods Media – Books, Movies, Music	5815
        //Digital Goods – Applications (Excludes Games)	5817
        //Digital Goods – Games	5816
        //Digital Goods – Large Digital Goods Merchant	5818
        //Direct Marketing - Catalog Merchant	5964
        //Direct Marketing - Combination Catalog and Retail Merchant	5965
        //Direct Marketing - Inbound Telemarketing	5967
        "5960": {
            id: "5960",
            name: "Direct Marketing - Insurance Services"
        },
        "5969": {
            id: "5969",
            name: "Direct Marketing - Other"
        },
        //Direct Marketing - Outbound Telemarketing	5966
        //Direct Marketing - Subscription	5968
        //Direct Marketing - Travel	5962
        //Discount Stores	5310
        "8011": {
            id: "8011",
            name: "Doctors"
        },
        "5963": {
            id: "5963",
            name: "Door-To-Door Sales"
        },//
        //Drapery, Window Covering, and Upholstery Stores	5714
        //Drinking Places	5813
        "5912": {
            id: "5912",
            name: "Drug Stores and Pharmacies"
        }, //Drugs, Drug Proprietaries, and Druggist Sundries	5122
        "5099": {
            id: "5099",
            name: "Durable Goods (Not Elsewhere Classified)"
        }, //Duty Free Stores	5309
        "5812": {
            id: "5812",
            name: "Eating Places, Restaurants"
        },
        "8299": {
            id: "8299",
            name: "Educational Services"
        },
        "5997": {
            id: "5997",
            name: "Electric Razor Stores"
        }, //Electrical Parts and Equipment	5065
        "1731": {
            id: "1731",
            name: "Electrical Services"
        },//Electronics Repair Shops	7622
        //Electronics Stores	5732
        //Elementary, Secondary Schools	8211
        "7361": {
            id: "7361",
            name: "Employment/Temp Agencies"
        },
        "7394": {
            id: "7394",
            name: "Equipment Rental"
        }, //Exterminating Services	7342
        //Family Clothing Stores	5651
        //Fast Food Restaurants	5814
        //Financial Institutions	6012
        //Fines - Government Administrative Entities	9222
        //Fireplace, Fireplace Screens, and Accessories Stores	5718
        //Floor Covering Stores	5713
        "5992": {
            id: "5992",
            name: "Florists"
        }, //Florists Supplies, Nursery Stock, and Flowers	5193
        //Freezer and Locker Meat Provisioners	5422
        "5983": {
            id: "5983",
            name: "Fuel Dealers (Non Automotive)"
        }, //Funeral Services, Crematories	7261
        "7641": {
            id: "7641",
            name: "Furniture Repair, Refinishing"
        },
        "5712": {
            id: "5712",
            name: "Furniture, Home Furnishings, and Equipment Stores, Except Appliances"
        },//Furriers and Fur Shops	5681
        "1520": {
            id: "1520",
            name: "General Services"
        },
        "5947": {
            id: "5947",
            name: "Gift, Card, Novelty, and Souvenir Shops"
        },
        "5231": {
            id: "5231",
            name: "Glass, Paint, and Wallpaper Stores"
        },//Glassware, Crystal Stores	5950
        //Golf Courses - Public	7992
        //Government Services (Not Elsewhere Classified)	9399
        "5411": {
            id: "5411",
            name: "Grocery Stores, Supermarkets"
        },
        "5251": {
            id: "5251",
            name: "Hardware Stores"
        },//Hardware, Equipment, and Supplies	5072
        "7298": {
            id: "7298",
            name: "Health and Beauty Spas"
        },//Hearing Aids Sales and Supplies	5975
        "1711": {
            id: "1711",
            name: "Heating, Plumbing, A/C"
        },
        "5945": {
            id: "5945",
            name: "Hobby, Toy, and Game Shops"
        },
        "5200": {
            id: "5200",
            name: "Home Supply Warehouse Stores"
        },
        "8062": {
            id: "8062",
            name: "Hospitals"
        },
        "7011": {
            id: "7011",
            name: "Hotels, Motels, and Resorts"
        }, //Household Appliance Stores	5722
        "5085": {
            id: "5085",
            name: "Industrial Supplies (Not Elsewhere Classified)"
        },
        //Information Retrieval Services	7375
        //Insurance - Default	6399
        //Insurance Underwriting, Premiums	6300
        //Intra-Company Purchases	9950
        "5944": {
            id: "5944",
            name: "Jewelry Stores, Watches, Clocks, and Silverware Stores"
        },//Landscaping Services	0780
        "7211": {
            id: "7211",
            name: "Laundries"
        },
        "7210": {
            id: "7210",
            name: "Laundry, Cleaning Services"
        }, //Legal Services, Attorneys	8111
        //Luggage and Leather Goods Stores	5948
        //Lumber, Building Materials Stores	5211
        //Manual Cash Disburse	6010
        //Marinas, Service and Supplies	4468
        //Masonry, Stonework, and Plaster	1740
        "7297": {
            id: "7297",
            name: "Massage Parlors"
        },
        "8099": {
            id: "8099",
            name: "Medical Services"
        },
        //Medical and Dental Labs	8071
        //Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies	5047
        //Membership Organizations	8699
        "5611": {
            id: "5611",
            name: "Mens and Boys Clothing and Accessories Stores"
        }, //Mens, Womens Clothing Stores	5691
        //Metal Service Centers	5051
        //Miscellaneous Apparel and Accessory Shops	5699
        //Miscellaneous Auto Dealers	5599
        //Miscellaneous Business Services	7399
        //Miscellaneous Food Stores - Convenience Stores and Specialty Markets	5499
        //Miscellaneous General Merchandise	5399
        //Miscellaneous General Services	7299
        //Miscellaneous Home Furnishing Specialty Stores	5719
        //Miscellaneous Publishing and Printing	2741
        //Miscellaneous Recreation Services	7999
        //Miscellaneous Repair Shops	7699
        //Miscellaneous Specialty Retail	5999
        "5271": {
            id: "5271",
            name: "Mobile Home Dealers"
        }, //Motion Picture Theaters	7832
        //Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services	4214
        //Motor Homes Dealers	5592
        //Motor Vehicle Supplies and New Parts	5013
        //Motorcycle Shops and Dealers	5571
        "5561": {
            id: "5561",
            name: "Motorcycle Shops, Dealers"
        },
        //Music Stores-Musical Instruments, Pianos, and Sheet Music	5733
        //News Dealers and Newsstands	5994
        //Non-FI, Money Orders	6051
        //Non-FI, Stored Value Card Purchase/Load	6540
        //Nondurable Goods (Not Elsewhere Classified)	5199
        "5261": {
            id: "5261",
            name: "Nurseries, Lawn and Garden Supply Stores"
        },
        "8050": {
            id: "8050",
            name: "Nursing/Personal Care"
        },//Office and Commercial Furniture	5021
        //Opticians, Eyeglasses	8043
        //Optometrists, Ophthalmologist	8042
        //Orthopedic Goods - Prosthetic Devices	5976
        //Osteopaths	8031
        "5921": {
            id: "5921",
            name: "Package Stores-Beer, Wine, and Liquor"
        }, //Paints, Varnishes, and Supplies	5198
        //Parking Lots, Garages	7523
        //Passenger Railways	4112
        //Pawn Shops	5933
        "5995": {
            id: "5995",
            name: "Pet Shops, Pet Food, and Supplies"
        },
        "5172": {
            id: "5172",
            name: "Petroleum and Petroleum Products"
        }, //Photo Developing	7395
        "7221": {
            id: "7221",
            name: "Photographic Studios"
        },//Photographic, Photocopy, Microfilm Equipment, and Supplies	5044
        //Picture/Video Production	7829
        //Piece Goods, Notions, and Other Dry Goods	5131
        //Plumbing, Heating Equipment, and Supplies	5074
        //Political Organizations	8651
        //Postal Services - Government Only	9402
        //Precious Stones and Metals, Watches and Jewelry	5094
        //Professional Services	8999
        "4225": {
            id: "4225",
            name: "Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage"
        },//Quick Copy, Repro, and Blueprint	7338
        //Railroads	4011
        "6513": {
            id: "6513",
            name: "Real Estate Agents and Managers - Rentals"
        },//Record Stores	5735
        //Recreational Vehicle Rentals	7519
        //Religious Goods Stores	5973
        //Religious Organizations	8661
        "1761": {
            id: "1761",
            name: "Roofing/Siding, Sheet Metal"
        },//Secretarial Support Services	7339
        //Security Brokers/Dealers	6211
        "5541": {
            id: "5541",
            name: "Service Stations"
        },//Sewing, Needlework, Fabric, and Piece Goods Stores	5949
        //Shoe Repair/Hat Cleaning	7251
        "5661": {
            id: "5661",
            name: "Shoe Stores"
        }, //Small Appliance Repair	7629
        //Snowmobile Dealers	5598
        //Special Trade Services	1799
        //Specialty Cleaning	2842
        "5941": {
            id: "5941",
            name: "Sporting Goods Stores"
        },//Sporting/Recreation Camps	7032
        //Sports Clubs/Fields	7941
        //Sports and Riding Apparel Stores	5655
        "5972": {
            id: "5972",
            name: "Stamp and Coin Stores"
        }, //Stationary, Office Supplies, Printing and Writing Paper	5111
        //Stationery Stores, Office, and School Supply Stores	5943
        //Swimming Pools Sales	5996
        //TUI Travel - Germany	4723
        "5697": {
            id: "5697",
            name: "Tailors, Alterations"
        },//Tax Payments - Government Agencies	9311
        //Tax Preparation Services	7276
        "4121": {
            id: "4121",
            name: "Taxicabs/Limousines"
        },//Telecommunication Equipment and Telephone Sales	4812
        "4814": {
            id: "4814",
            name: "Telecommunication Services"
        },
        //Telegraph Services	4821
        //Tent and Awning Shops	5998
        "8734": {
            id: "8734",
            name: "Testing Laboratories"
        },//Theatrical Ticket Agencies	7922
        //Timeshares	7012
        //Tire Retreading and Repair	7534
        //Tolls/Bridge Fees	4784
        //Tourist Attractions and Exhibits	7991
        "7549": {
            id: "7549",
            name: "Towing Services"
        }, //Trailer Parks, Campgrounds	7033
        //Transportation Services (Not Elsewhere Classified)	4789
        "4722": {
            id: "4722",
            name: "Travel Agencies, Tour Operators"
        },//Truck StopIteration	7511
        //Truck/Utility Trailer Rentals	7513
        //Typesetting, Plate Making, and Related Services	2791
        //Typewriter Stores	5978
        //U.S. Federal Government Agencies or Departments	9405
        //Uniforms, Commercial Clothing	5137
        //Used Merchandise and Secondhand Stores	5931
        //Utilities	4900
        "5331": {
            id: "5331",
            name: "Variety Stores"
        },
        "0742": {
            id: "0742",
            name: "Veterinary Services"
        },//Video Amusement Game Supplies	7993
        "7994": {
            id: "7994",
            name: "Video Game Arcades"
        },
        "7841": {
            id: "7841",
            name: "Video Tape Rental Stores"
        },
        "8249": {
            id: "8249",
            name: "Vocational/Trade Schools"
        },
        "7631": {
            id: "7631",
            name: "Watch/Jewelry Repair"
        },
        "7692": {
            id: "7692",
            name: "Welding Repair"
        },
        "5300": {
            id: "5300",
            name: "Wholesale Clubs"
        },
        "5698": {
            id: "5698",
            name: "Wig and Toupee Stores"
        },
        "4829": {
            id: "4829",
            name: "Wires, Money Orders"
        },
        "5631": {
            id: "5631",
            name: "Womens Accessory and Specialty Shops"
        },
        "5621": {
            id: "5621",
            name: "Womens Ready-To-Wear Stores"
        },
        "5935": {
            id: "5935",
            name: "Wrecking and Salvage Yards"
        },
    },
    allCountries: [ // Taken from https://gist.github.com/unceus/6501985
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'Andorra', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic People\'s Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: 'Lao People\'s Democratic Republic', code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'Rwanda', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Vietnam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' }
  ]
};
