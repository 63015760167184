import { Injectable } from '@angular/core';
import { IAddonType, IAddonCategory, IModalDataEvent, IAddonFilter } from '../../../interfaces/common.interface';
import { constants } from 'src/app/constants/common.constants';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AddonsService {

    private modalDataEvent: Subject<IModalDataEvent>;
    private addOnCategories: Array<IAddonCategory>;
    private addOnTypes: Array<IAddonType>;
    private addOnFilters: Array<IAddonFilter>;
    public appOwnership: boolean = false;

    constructor() {
        this.modalDataEvent = new Subject<IModalDataEvent>();
        this.addOnTypes = constants.addOnTypes;
        this.addOnFilters = constants.addOnFilters;
        this.addOnCategories = constants.addOnCategories;
    }

    public lastToggleValue: boolean = false;

    addOnsToggle(isConstructor, toggleValue) {
        if (isConstructor) {
            return this.lastToggleValue;
        } else {
            this.lastToggleValue = toggleValue;
            return toggleValue;
        }
    }

    getAddOnCategories(): Array<IAddonCategory> {
        return this.addOnCategories;
    }

    resetAddOnCategories() {
        this.addOnCategories.map((category: IAddonCategory) => {
            category.selected = false;
        });
    }

    getAddOnTypes(): Array<IAddonType> {
        return this.addOnTypes;
    }

    getAddOnFilters(): Array<IAddonFilter> {
        return this.addOnFilters;
    }

    getmodalDataEvent(): Subject<IModalDataEvent> {
        return this.modalDataEvent;
    }

    resetAddOnTypes() {
        this.addOnTypes.map((type: IAddonType) => {
            if (type.label === 'all') {
                type.selected = true;
            } else {
                type.selected = false;
            }
        });
    }

    resetAddOnFilters() {
        this.addOnFilters.map((type: IAddonFilter) => {
            if (type.label === 'all') {
                type.selected = true;
            } else {
                type.selected = false;
            }
        });
    }

}
