import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ILoginData } from '../interfaces/common.interface';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient) { }

    // fetchCountryCode(): Promise<any> {
    //     return this.http.get(`${environment.api_url2}/requestCountryCodeGeoIP2`).toPromise();
    // }

    getPublicAddons(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/addOn/getPublicAddons`, requestPayload).toPromise();
    }

    getAddons(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/addOn/getAddOns`, requestPayload).toPromise();
    }

    getPublicAddon(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/addOn/getPublicAddons`, requestPayload).toPromise();
    }

    getAddon(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/addOn/getAddOns`, requestPayload).toPromise();
    }

    setCustomerCreditLimit(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/setCustomerCreditLimit`, requestPayload).toPromise();
    }

    setSafepass(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configure_safepass_addon`, requestPayload).toPromise();
    }

    getFuguChatDetail(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_fugu_add_on`, requestPayload).toPromise();
    }

    getBulbulDetails(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url_bulbul}/tookan_onboarding_status`, requestPayload).toPromise();
    }

    getNumberMaskingData(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_number_masking_add_on_settings`, requestPayload).toPromise();
    }

    getApiKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/v2/view_user_api_key`, requestPayload).toPromise();
    }

    generateApiKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/v2/generate_api_key`, requestPayload).toPromise();
    }

    getUserMessagingCredentials(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_user_messaging_credentials`, requestPayload).toPromise();
    }

    getUserBcc(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_user_bcc`, requestPayload).toPromise();
    }

    setUserBcc(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/update_user_bcc`, requestPayload).toPromise();
    }

    changeUserAddon(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/change_user_addon`, requestPayload).toPromise();
    }

    activateFleetSignUp(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/activate_fleet_signup`, requestPayload).toPromise();
    }

    getTookanPartnerProfile(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/fetch_tookan_partner_profile`, requestPayload).toPromise();
    }

    getTookanClientProfile(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/fetch_tookan_client_profile`, requestPayload).toPromise();
    }

    getOtpAddonSettings(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_otp_add_on_settings`, requestPayload).toPromise();
    }

    getMonibyteCredentials(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_monibyte_add_on_settings`, requestPayload).toPromise();
    }

    updateMonibyteCredentials(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configure_monibyte_add_on`, requestPayload).toPromise();
    }

    getUbereatCredentials(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/uberEats/getKeys`, requestPayload).toPromise();
    }

    updateUbereatCredentials(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/uberEats/addKeys`, requestPayload).toPromise();
    }

    getStoreDetails(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/uberEats/getStoreDetails`, requestPayload).toPromise();
    }

    statusStoreDetails(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/uberEats/getPOSstatus`, requestPayload).toPromise();
    }

    updateStoreDetails(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/uberEats/UpdatePOSstatus`, requestPayload).toPromise();
    }

    getWebkulCredentials(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_webkul_with_shopify_add_on_settings`, requestPayload).toPromise();
    }

    updateWebkulCredentials(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configure_webkul_with_shopify_add_on`, requestPayload).toPromise();
    }

    getMapConfig(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_map_config`, requestPayload).toPromise();
    }

    setMapConfig(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/set_map_config`, requestPayload).toPromise();
    }

    getTrackingUrlConfig(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_user_extras`, requestPayload).toPromise();
    }

    setTrackingUrlConfig(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/set_tracking_link`, requestPayload).toPromise();
    }

    getTollguruCredentials(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_toll_fare_add_on`, requestPayload).toPromise();
    }

    updateTollguruCredentials(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configure_toll_fare_add_on`, requestPayload).toPromise();
    }

    getFleetWalletConfig(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/fetch_fleet_wallet_config`, requestPayload).toPromise();
    }

    setFleetWalletConfig(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/config_fleet_wallet`, requestPayload).toPromise();
    }

    submitNumberMasking(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configure_number_masking_add_on`, requestPayload).toPromise();
    }

    getStripeConnectData(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_stripe_connect_add_on_settings`, requestPayload).toPromise();
    }

    updateStripeConnectData(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configure_stripe_connect_add_on`, requestPayload).toPromise();
    }

    activateBusiness(requestPayload): Promise<any> {
        return this.http.post(`${environment.fugu_base_url}/api/reseller/activateBusiness`, requestPayload).toPromise();
    }

    deactivateBusiness(requestPayload): Promise<any> {
        return this.http.post(`${environment.fugu_base_url}/api/reseller/deactivateBusiness`, requestPayload).toPromise();
    }

    updateCustomerAddon(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/update_customer_addon`, requestPayload).toPromise();
    }

    updateOpenTokSetings(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/add_update_opentok_settings`, requestPayload).toPromise();
    }

    updateRequestOtp(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configure_otp_add_on`, requestPayload).toPromise();
    }

    getCheckrAddonConfig(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_checkr_add_on_settings`, requestPayload).toPromise();
    }

    updateCheckrAddonConfig(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configure_checkr_add_on`, requestPayload).toPromise();
    }

    updateTrackerConfig(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configure_tracker_add_on`, requestPayload).toPromise();
    }

    updateScanToAssignConfigModal(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configureScanToAssign`, requestPayload).toPromise();
    }
    getScanToAssignConfigModal(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/getScanToAssignSettings`, requestPayload).toPromise();
    }

    updateBarcodeConfig(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/set_driver_app_settings`, requestPayload).toPromise();
    }

    getAgentTags(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_fleet_tags`, requestPayload).toPromise();
    }

    getTeams(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/view_team_only`, requestPayload).toPromise();
    }
    viewAllTeams(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/view_all_team`, requestPayload).toPromise();
    }

    getFleets(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_available_fleets`, requestPayload).toPromise();
    }

    getDispatchers(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/view_all_dispatcher`, requestPayload).toPromise();
    }


    getWoocommerceKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/woo/getKeys`, requestPayload).toPromise();
    }

    setWoocommerceKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/woo/addKeys`, requestPayload).toPromise();
    }

    getRushourKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/rushour/getKeys`, requestPayload).toPromise();
    }

    setRushourKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/rushour/setKeys`, requestPayload).toPromise();
    }

    getDeliverooKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/deliveroo/getKeys`, requestPayload).toPromise();
    }

    setDeliverooKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/deliveroo/setKeys`, requestPayload).toPromise();
    }

    getDeliverectKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/deliverect/getKeys`, requestPayload).toPromise();
    }

    setDeliverectKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/deliverect/setKeys`, requestPayload).toPromise();
    }

    getOrkestroKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/orkestro/getKeys`, requestPayload).toPromise();
    }

    setOrkestroKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/orkestro/setKeys`, requestPayload).toPromise();
    }
    
    getBigCommerceKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/bigcommerce/getKeys`, requestPayload).toPromise();
    }

    setBigCommerceKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/bigcommerce/setKeys`, requestPayload).toPromise();
    }

    getCscartKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/cscart/getKeys`, requestPayload).toPromise();
    }

    setCscartKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/cscart/setKeys`, requestPayload).toPromise();
    }

    getGloriaKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.gloriafood_url}/get_user_gloria_config`, requestPayload).toPromise();
    }

    setGloriaKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.gloriafood_url}/upsert_user_gloria_config`, requestPayload).toPromise();
    }

    getSquareUpKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/squareup/getKeys`, requestPayload).toPromise();
    }

    setSquareUpKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/squareup/setKeys`, requestPayload).toPromise();
    }

    getPetpoojaKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/petpooja/getKeys`, requestPayload).toPromise();
    }

    setPetpoojaKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/petpooja/insertKeys`, requestPayload).toPromise();
    }

    getPayoutAddon(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_payout_add_on_settings`, requestPayload).toPromise();
    }

    setPayoutAddon(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configure_payout_add_on`, requestPayload).toPromise();
    }

    getShipstreamKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/shipstream/getKeys`, requestPayload).toPromise();
    }

    setShipstreamKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/shipstream/setKeys`, requestPayload).toPromise();
    }

    zohoLogin(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/zohoCommerce/login`, requestPayload).toPromise();
    }

    getzohoShop(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/zohoCommerce/get_shop`, requestPayload).toPromise();
    }

    addzohoShop(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/zohoCommerce/add_shop`, requestPayload).toPromise();
    }

    getDealposKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/dealpos/getKeys`, requestPayload).toPromise();
    }

    setDealposKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/dealpos/setKeys`, requestPayload).toPromise();
    }

    getGrubtechKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/grubtech/getKeys`, requestPayload).toPromise();
    }

    setGrubtechKey(requestPayload): Promise<any> {
        return this.http.post(`${environment.integration_url}/grubtech/setKeys`, requestPayload).toPromise();
    }

    getCapacity(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_capacity_addon`, requestPayload).toPromise();
    }

    setCapacity(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/set_capacity_addon`, requestPayload).toPromise();
    }

    getInventory(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_inventory_config`, requestPayload).toPromise();
    }

    setInventory(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/configure_inventory_addon`, requestPayload).toPromise();
    }

    getReminderSettings(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_agent_schedule_settings`, requestPayload).toPromise();
    }

    getAgentScheduleAccess(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_availability_access`, requestPayload).toPromise();
    }

    getAgentSchedule(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/get_account_availability`, requestPayload).toPromise();
    }

    setCustomerSchedule(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/set_default_customer_schedule`, requestPayload).toPromise();
    }

    setAgentSchedule(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/set_account_availability`, requestPayload).toPromise();
    }

    setAgentScheduleAccess(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/set_availability_access`, requestPayload).toPromise();
    }

    setUpIntent(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/set_up_intent`, requestPayload).toPromise();
    }

    addCardLog(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/log_user_credit_card_try`, requestPayload).toPromise();
    }

    addCard(requestPayload): Promise<any> {
        return this.http.post(`${environment.api_url}/add_credit_card`, requestPayload).toPromise();
    }

    sendEmailForKatoAddon(loginData: ILoginData) {
        const html = '<p> The below user showed an interest in KATOAI Add-On.</p><p><b>Email Id: ' + (loginData && loginData.email || '') + '</b><p><b>User ID: ' + (loginData && loginData.user_id || '') + '</b></p></p>';
        return this.http.post(`${environment.api_url}/send_html_content_email`, {
            adminEmail: 'nitish.gupta@click-labs.com',
            subject: 'Customer Interested in KATO Add-on',
            html,
            userEmail: 'contact@tookanapp.com'
        }).toPromise();
    }

    updateROConfig(requestPayload): Promise<any> {
      return this.http.post(`${environment.api_url}/set_RO_config`, requestPayload).toPromise();
    }

    getROConfig(requestPayload): Promise<any> {
      return this.http.post(`${environment.api_url}/get_RO_config`, requestPayload).toPromise();
    }

    updateShopifyConfig(requestPayload): Promise<any> {
       return this.http.post(`${environment.api_url}/set_shopify_config`, requestPayload).toPromise();
    }
  
    getShopifyConfig(requestPayload): Promise<any> {
       return this.http.post(`${environment.api_url}/get_shopify_config`, requestPayload).toPromise();
    }

    uploadRefImage(payload): Promise<any> {
        return this.http.post(`${environment.api_url}/upload_ref_images`, payload).toPromise();
    }

    registerDO(payload): Promise<any> {
        return this.http.post(`${environment.api_url}/deliveryMerchant/register`, payload).toPromise();
    }

    getDeliveryMerchantData(payload): Promise<any> {
        return this.http.post(`${environment.api_url}/deliveryMerchant/getDeliveryMerchantData`, payload).toPromise();
    }

}
